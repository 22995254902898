import React from 'react';

import { Grid } from '@material-ui/core';
import { TitlePage } from '@styles/styles.global';
import LogoSociallis from '@assets/Login/sociallis-logo-hor2.png';
import IconSociallis from '@assets/Login/sociallis-logo-icon.png';



export default function Footer2 () {
  return (
    <Grid container style={{backgroundColor: "#0066B1", paddingTop: 7, paddingBottom: 7}}>
      <Grid container alignItems="center" justify="center" style={{paddingTop: 2}}>
        <Grid container item lg={12} sm={12} xs={12} alignItems="center" justify="center" >
          <TitlePage style={{paddingRight: 10, paddingTop: 3}}><strong></strong>Desenvolvido por </TitlePage>

            <img src={IconSociallis} style={{paddingRight:2, width: 23, height: 23 }} />
            <img src={LogoSociallis} style={{paddingLeft:2, width: 70, height: 33 }} />

        </Grid>
      </Grid>
  </Grid>
  );
}
