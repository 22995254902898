import React, { useState } from 'react';

import { getColors } from '@styles/colorsTheme';
import { CloseOutlined } from '@material-ui/icons';
import Selo from '@assets/Login/selo-socioprotetor-30anos-osid.png'

import { Grid } from '@material-ui/core';
import '@styles/AntStyles.css';
import { addDays } from 'date-fns';

const Termo: React.FC = () => {

  function handleModalClose() {
    window.location.reload();
  }



  return (
    <>
      <Grid container>

        {/* ------------------- BACKGROUND ----------------------- */}
        <Grid container item lg={12} sm={12} xs={12}
          style={{
            background: `url('https://image-emails.s3.sa-east-1.amazonaws.com/OSID/bg-osid-doe.png')`,
            //backgroundSize: '100% 100%',
            backgroundSize: 'cover',
            //height: '90vh',
            //width: '99.3vw',
            position: 'relative',
            backgroundPosition: 'center',
            //padding: 5,
            //paddingTop: 0,
          }}
        >



            {/* ----------------------------- ETAPA 0 (PRIMEIRA PÁGINA) --------------------*/}


              <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 103, marginBottom: 95}}>

                <Grid container item lg={12} sm={12} xs={12}>
                  <Grid  container item lg={4} sm={4} xs={12} justify="center" style={{marginLeft: 20}}></Grid>

                  <Grid  container item lg={4} sm={4} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5, backgroundColor:'#0066B1',}}>

                    <Grid  container item lg={12} sm={12} xs={12} justify='flex-end' style={{textAlign:'end', marginRight: 5,}}>
                      <button
                        style={{marginTop: 10,  }}
                        onClick={() => {
                          handleModalClose();
                        }}
                      >
                        <CloseOutlined
                          fontSize="medium"
                          htmlColor={getColors()?.branco}
                        />
                      </button>
                    </Grid>

                    <Grid  container item lg={12} sm={12} xs={12} justify="center">
                      <img src={Selo} style={{width: 100, height: 100}}></img>
                    </Grid>

                    <Grid  container item lg={2} sm={2} xs={12} justify="center"></Grid>

                    <Grid  container item lg={8} sm={8} xs={12} justify="center">

                      <p style={{textAlign:'start', color:'#fff', fontWeight: 900, fontSize: '19px', marginTop: 20}}>
                        Bem vindo(a) ao programa Sócio Protetor!
                      </p>
                      <p style={{textAlign:'start', color:'#fff', fontWeight: 900, fontSize: '19px', marginTop: 2}}>
                      Através de pessoas como você, seguimos mantendo vivo o legado de Amar e Servir.
                      </p>
                      <p style={{textAlign:'start', color:'#fff', fontWeight: 900, fontSize: '19px', marginTop: 2}}>
                      As Obras Sociais Irmã Dulce agradecem sua doação.
                      </p>


                      <p style={{textAlign:'start', color:'#fff', fontSize: '11px', marginTop: 60, marginBottom: 70}}>


                      </p>
                    </Grid>

                    <Grid  container item lg={2} sm={2} xs={12} justify="center"></Grid>

                  </Grid>

                  <Grid  container item lg={4} sm={4} xs={12} justify="center"></Grid>
                </Grid>
              </Grid>
        </Grid> {/* background */}

      </Grid>



    </>
  );
};

export default Termo;
