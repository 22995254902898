import styled from 'styled-components';

export const TitlePage = styled.h1`
font-family: 'Poppins', sans-serif;
color: #fff;
font-size: 12px;
font-weight: 600;
font-style: normal;
font-display: swap;

`;

export const SubTitle = styled.h3`
  color: #616161;
  font-weight: normal;
  font-size: 14px;
`;

export const SubTitleBold = styled.h3`
  color: #616161;
  font-weight: bold;
  font-size: 16px;
`;

//font-family: var(--ubuntu);
//font-weight: 400;
//font-size: 0.87rem;
//line-height: 1.6rem;
//color: #191919;
