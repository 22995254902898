import React from 'react';
import LogoSelo from '@assets/Login/logoselo_osid.png'

import { Grid } from '@material-ui/core';

export default function Footer () {
  return (
    <Grid container style={{paddingTop: 25, paddingBottom: 20}}>
    <Grid container item lg={12} sm={12} xs={12} justify="center">
      <Grid  container item lg={1} sm={12} xs={12} style={{paddingLeft: 0}}></Grid>

      <Grid  container item lg={3} sm={12} xs={12} justify="center" >
        <img src={LogoSelo} style={{ width: 300, height: 90}} />
      </Grid>

      <Grid container item lg={3} sm={6} xs={12}  justify="center">
        <p style={{paddingTop: 3}}>
          <p style={{fontSize: 12, color: '#cd8a4d'}}>Central de Relacionamento com o Doador</p>
          <p><strong style={{fontSize: 17, color: '#0066B1',fontWeight: 900}}>(71) 3316-8899</strong></p>
          <p style={{fontSize: 17, color: '#0066B1', fontWeight: 900}}>socioprotetor@irmadulce.org.br</p>
        </p>
      </Grid>

      <Grid container item lg={3} sm={6} xs={12} justify="center"  >
        <p style={{paddingTop: 3}}>
          <p style={{fontSize: 12, color: '#cd8a4d'}}>Horário de funcionamento</p>
          <p><strong style={{fontSize: 17, color: '#0066B1', fontWeight: 900}}>segunda a sexta-feira das</strong></p>
          <p><strong style={{fontSize: 17, color: '#0066B1', fontWeight: 900}}>9h às 17h30 (exceto feriados)</strong></p>
        </p>

      </Grid>

      <Grid  container item lg={1} sm={12} xs={12} style={{paddingLeft: 0}}></Grid>

    </Grid>
  </Grid>
  );
}
